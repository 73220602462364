import React, { useState, useEffect } from 'react';
import {
  Alert,
  Button,
  Modal,
  Spinner,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import { useSelector } from 'react-redux';
import './UploadItemsModal.css';
import {
  postProductSet,
  putProductSet,
} from '../../../controllers/product-service';
import {
  ResultsProductIncompatibleMessage,
  PortfolioResultsV2,
  ResultsProductNewMessage,
  ResultsProductChangedMessage,
  ResultsProductUnchangedMessage,
  SearchProductRowMessage,
} from '../../../controllers/product-service/types';
import { AsyncState } from '../../../utils/webRequests.type';
import { selectCurrentOrgId } from '../../../reducers/profile/profileSlice';
import sendRumError from '../../../utils/datadogRum';
import ListResultItems from '../ListResultItems/ListResultItems';

// ! TODO: concerned about the use of an a-ref here... should be Link?
const warningErrorMessageDisplay = (
  details: SearchProductRowMessage[],
) => (
  <div>
    <ul>
      {details.map(
        (detail) => (
          <li key={detail.message}>
            {detail.message}
            {
              detail.informationLink
                ? (
                  <span>
                    {' Click '}
                    <Link
                      className="ma-link"
                      target="_blank"
                      rel="noreferrer"
                      to={detail.informationLink}
                    >
                      here
                    </Link>
                    {' to see an example of a valid URL format.'}
                  </span>
                )
                : ''
            }
          </li>
        ),
      )}
    </ul>
  </div>
);

const errorColumns: Column<ResultsProductIncompatibleMessage>[] = [
  {
    Header: 'Row #',
    accessor: 'row_number',
  },
  {
    Header: 'Retailer',
    accessor: (d) => d.payload_received.retailer_name,
  },
  {
    Header: 'Retailer SKU',
    accessor: (d) => d.payload_received.retailer_sku,
  },
  {
    Header: 'Manufacturer SKU',
    accessor: (d) => d.payload_received.manufacturer_sku,
  },
  {
    Header: 'Warnings',
    accessor: (d) => warningErrorMessageDisplay(d.warnings),
  },
  {
    Header: 'Errors',
    accessor: (d) => warningErrorMessageDisplay(d.errors),
  },
];

const newProductColumns: Column<ResultsProductNewMessage>[] = [
  {
    Header: 'Retailer',
    accessor: (d) => d.new_product_info.retailer_name,
  },
  {
    Header: 'Retailer SKU',
    accessor: (d) => d.payload_to_send.retailer_sku,
  },
  {
    Header: 'Manufacturer SKU',
    accessor: (d) => d.payload_to_send.manufacturer_sku,
  },
  {
    Header: 'Model Name',
    accessor: (d) => d.payload_to_send.model_name,
  },
  {
    Header: 'Warnings',
    accessor: (d) => warningErrorMessageDisplay(d.warnings),
  },
];

const changedProductColumns: Column<ResultsProductChangedMessage>[] = [
  {
    Header: 'Retailer',
    accessor: (d) => d.changed_product_info.retailer_name,
  },
  {
    Header: 'Retailer SKU',
    accessor: (d) => d.payload_to_send.retailer_sku,
  },
  {
    Header: 'Manufacturer SKU',
    accessor: (d) => d.payload_to_send.manufacturer_sku,
  },
  {
    Header: 'Model Name',
    accessor: (d) => d.payload_to_send.model_name,
  },
  {
    Header: 'Warnings',
    accessor: (d) => warningErrorMessageDisplay(d.warnings),
  },
];

const unchangedProductColumns: Column<ResultsProductUnchangedMessage>[] = [
  {
    Header: 'Retailer',
    accessor: (d) => d.unchanged_product_info.retailer_name,
  },
  {
    Header: 'Retailer SKU',
    accessor: (d) => d.current.retailer_sku,
  },
  {
    Header: 'Manufacturer SKU',
    accessor: (d) => d.current.manufacturer_sku,
  },
  {
    Header: 'Model Name',
    accessor: (d) => d.current.model_name,
  },
  {
    Header: 'Warnings',
    accessor: (d) => warningErrorMessageDisplay(d.warnings),
  },
];

type UploadItemsModalSummaryProps = {
  data: PortfolioResultsV2,
  setDisplayModal: React.Dispatch<React.SetStateAction<boolean>>,
  displayModal: boolean,
};

function UploadItemsModalSummary(props: UploadItemsModalSummaryProps): JSX.Element {
  const [uploadError, setUploadError] = useState<boolean>(false);
  const [uploadStatus, setUploadStatus] = useState<AsyncState>('uninitialized');
  const [disableUpload, setDisableUpload] = useState<boolean>(false);
  const currentOrgId = useSelector(selectCurrentOrgId);
  const { data, displayModal, setDisplayModal } = props;
  const [applyButtonVariant, setApplyButtonVariant] = useState<'primary' | 'danger'>('primary');
  const [closeButtonVariant, setCloseButtonVariant] = useState<'primary' | 'danger'>('danger');
  const [applyButtonVisibility, setApplyButtonVisibility] = useState<boolean>(true);

  const {
    incompatible_products: incompatibleProducts,
    new_products: newProducts,
    changed_products: changedProducts,
    unchanged_products: unchangedProducts,
  } = data;

  const areChangesProcessed = (
    (newProducts ?? []).length + (changedProducts ?? []).length
  ) !== 0;

  const handleClose = () => {
    const currentUploadStatus = uploadStatus.toString();
    setDisplayModal(false);
    setUploadError(false);
    setUploadStatus('uninitialized');
    if (currentUploadStatus === 'completed') {
      window.location.reload();
    }
  };

  const handleSubmit = () => {
    setUploadStatus('loading');

    const promiseList = [];

    if (newProducts.length > 0) {
      const newProductsList = newProducts.map((p) => p.payload_to_send);
      promiseList.push(postProductSet(currentOrgId as number, newProductsList));
    }

    if (changedProducts.length > 0) {
      const changedProductsList = changedProducts.map((p) => p.payload_to_send);
      promiseList.push(putProductSet(currentOrgId as number, changedProductsList));
    }

    Promise.all(promiseList).catch((error) => {
      sendRumError(error);
      setUploadError(true);
    })
      .finally(() => setUploadStatus('completed'));
  };

  useEffect(() => {
    if (uploadStatus === 'completed') {
      setCloseButtonVariant('danger');
      setApplyButtonVisibility(true);
      setApplyButtonVariant('primary');
    } else if (uploadStatus === 'failed' || !areChangesProcessed || uploadError) {
      setCloseButtonVariant('danger');
      setApplyButtonVisibility(false);
      setApplyButtonVariant('danger');
    } else {
      setCloseButtonVariant('danger');
      setApplyButtonVisibility(true);
      setApplyButtonVariant('primary');
    }
  }, [uploadStatus, areChangesProcessed, uploadError]);

  useEffect(() => {
    setDisableUpload(uploadStatus !== 'uninitialized' || !areChangesProcessed || uploadError);
  }, [uploadStatus, uploadError, areChangesProcessed]);

  return (
    <Modal
      className="upload-item-modal-container summary-modal"
      data-testid="upload-item-modal-container"
      show={displayModal}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Item Changes Summary</Modal.Title>
      </Modal.Header>
      <Modal.Body
        data-testid="item-changes-summary-body"
      >
        <p>
          Please review the change summaries below and confirm your changes by pressing the&nbsp;
          <b>Apply Changes</b>
          &nbsp;button below.
        </p>
        <p>
          Item additions or edits resulting in errors require that you address the error and
          reupload an item&apos;s information in order for it to appear in the application.
        </p>
        <p>
          All successful additions and edits may take up to 24 hours to appear in the application.
        </p>
        <ListResultItems<ResultsProductNewMessage>
          eventKey="1"
          data={newProducts}
          dataColumns={newProductColumns}
          headerClass="text-success"
          headerText="Will be Added"
        />
        <ListResultItems<ResultsProductChangedMessage>
          eventKey="2"
          data={changedProducts}
          dataColumns={changedProductColumns}
          headerClass="text-deep-sea-blue"
          headerText="Will be Changed"
        />
        <ListResultItems<ResultsProductIncompatibleMessage>
          eventKey="3"
          data={incompatibleProducts}
          dataColumns={errorColumns}
          headerClass="text-danger"
          headerText="with Errors"
        />
        <ListResultItems<ResultsProductUnchangedMessage>
          eventKey="4"
          data={unchangedProducts}
          dataColumns={unchangedProductColumns}
          headerClass="text-dark"
          headerText="Unchanged"
        />
      </Modal.Body>
      <Modal.Footer>
        {
          uploadStatus === 'completed' && !uploadError && (
            <Alert variant="success" className="w-100">
              All changes have been applied.
            </Alert>
          )
        }
        {
          uploadStatus === 'completed' && uploadError && (
            <Alert variant="danger" className="w-100">
              Failed to upload changes. Please refresh and try again.
            </Alert>
          )
        }
        {
          !areChangesProcessed && (
            <Alert variant="danger" className="w-100">
              Changes cannot be submitted because all submissions
              would result in errors or no changes.
            </Alert>
          )
        }
        <Button
          variant={closeButtonVariant}
          data-testid="upload-items-modal-summary-close"
          onClick={handleClose}
        >
          Close
        </Button>
        {
          applyButtonVisibility && (
            <Button
              variant={applyButtonVariant}
              id="item-upload-modal-confirm-button"
              data-testid="item-upload-modal-confirm-button"
              onClick={handleSubmit}
              className="m-1"
              disabled={disableUpload}
              type="submit"
            >
              {
                uploadStatus === 'uninitialized' && areChangesProcessed && <>Apply Changes</>
              }
              {
                uploadStatus === 'loading' && <Spinner animation="grow" size="sm" />
              }
              {
                uploadStatus === 'completed' && <>Apply Changes</>
              }
            </Button>
          )
        }
      </Modal.Footer>
    </Modal>
  );
}

export default UploadItemsModalSummary;
