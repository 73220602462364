import axios, { AxiosInstance } from 'axios';
import { headers } from '..';
import useAuthenticationInterceptors from '../auth/useAuthenticationInterceptors';
import {
  DataCollectionAccount,
  DataCollectionAccountPayload,
  DataCollectionAccountSettingsUpdatePayload,
  DataCollectionAccountUpdatePayload,
  DataCollectionAccountUpdateResponse,
  OrganizationSubscription,
  OrganizationSubscriptionSetting,
  OrganizationSubscriptionSettingsResponse,
  OrganizationSubscriptionSettingsUpdatePayload,
  SubscriptionSettingsRequirement,
  ValidatedSettingsResponse,
} from './types';

type OrganizationSubscribedCollectionTypesCacheDict = {
  [key: string]: {
    expiration: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: any,
  },
};

let subscriptionService: AxiosInstance;

const getSubscriptionsFromStorage = (): OrganizationSubscribedCollectionTypesCacheDict => {
  const SUBSCRIPTION_STORAGE = localStorage.getItem('subscriptionStore');

  return SUBSCRIPTION_STORAGE
    ? JSON.parse(SUBSCRIPTION_STORAGE) as unknown as OrganizationSubscribedCollectionTypesCacheDict
    : {};
};

export const initializeSubscriptionController = (serviceUrl: string) => {
  subscriptionService = axios.create({
    baseURL: serviceUrl,
    // reformats lists in params.. does not include the `[]`
    paramsSerializer: { indexes: null },
  });

  useAuthenticationInterceptors(subscriptionService);
};

// updated to latest schema 2024-05-15
export const fetchOrganizationSubscriptions = async (
  orgId: number,
  collectionTypeList: string[] | undefined = undefined,
  retailers: string[] | undefined = undefined,
) => subscriptionService.get<OrganizationSubscription[]>(
  '/organization_subscriptions/',
  {
    headers: headers(orgId),
    params: {
      collection_type_list: collectionTypeList,
      retailers,
    },
  },
);

// updated to call the above function and fixed name 2024-05-15
export const fetchOrganizationSubscriptionsWithCaching = async (
  orgId: number,
) => {
  const oId = String(orgId);
  const organizationSubscribedCollectionTypesCache = getSubscriptionsFromStorage();
  if (
    organizationSubscribedCollectionTypesCache[oId]
    && organizationSubscribedCollectionTypesCache[oId].expiration < Date.now()
  ) {
    return organizationSubscribedCollectionTypesCache[oId].response;
  }
  delete organizationSubscribedCollectionTypesCache[oId];
  const response = await fetchOrganizationSubscriptions(orgId);
  organizationSubscribedCollectionTypesCache[oId] = {
    expiration: Date.now() + 300,
    response,
  };
  return response;
};

// updated to latest schema 2024-05-15
export const fetchSubscriptionSettingsRequirements = async (
  orgId: number,
) => subscriptionService.get<SubscriptionSettingsRequirement[]>(
  '/subscription_settings_requirements/',
  {
    headers: headers(orgId),
  },
);

export const fetchOrganizationSubscriptionSettings = async (
  orgId: number,
) => subscriptionService.get<OrganizationSubscriptionSetting[]>(
  '/data_collection_account_settings/',
  {
    headers: headers(orgId),
  },
);

export const patchOrganizationSubscriptionSetting = async (
  orgId: number,
  settingId: string,
  payload: OrganizationSubscriptionSettingsUpdatePayload,
) => subscriptionService.patch<OrganizationSubscriptionSettingsResponse>(
  `/data_collection_account_setting/${settingId}`,
  payload,
  {
    headers: headers(orgId),
  },
);

// DCAs
export const getDataCollectionAccounts = async (
  orgId: number,
) => subscriptionService.get<DataCollectionAccount[]>(
  '/data_collection_accounts/',
  {
    headers: headers(orgId),
  },
);

export const deleteDataCollectionAccount = async (
  orgId: number,
  dcaId: string,
) => subscriptionService.delete(
  `/data_collection_accounts/${dcaId}`,
  {
    headers: headers(orgId),
  },
);

export const createDataCollectionAccount = async (
  orgId: number,
  payload: DataCollectionAccountPayload,
) => subscriptionService.post<DataCollectionAccount>(
  '/data_collection_accounts/',
  payload,
  {
    headers: headers(orgId),
  },
);

export const updateDataCollectionAccount = async (
  orgId: number,
  dcaId: string,
  payload: DataCollectionAccountUpdatePayload,
) => subscriptionService.patch<DataCollectionAccountUpdateResponse>(
  `/data_collection_accounts/${dcaId}`,
  payload,
  {
    headers: headers(orgId),
  },
);

export const updateDataCollectionAccountSettings = async (
  orgId: number,
  dcaId: string,
  payload: DataCollectionAccountSettingsUpdatePayload,
) => subscriptionService.patch<ValidatedSettingsResponse[]>(
  `/data_collection_accounts/${dcaId}/organization_subscription_settings/`,
  payload,
  {
    headers: headers(orgId),
  },
);
